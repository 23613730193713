import { addListener, hideModal, isVariableDefinedNotNull, isVisible, openModal } from '@slideslive/fuse-kit/utils';
import GlobalObserver from 'modules/global_observer';

function initOpen(element) {
  const modalName = element.getAttribute('data-open-modal');

  addListener(element, 'click', (event) => {
    const target = event.currentTarget;
    const defaultAction =
      target.tagName.toLowerCase() === 'button' ||
      (target.tagName.toLowerCase() === 'a' &&
        element.getAttribute('href') !== '' &&
        element.getAttribute('href') !== '#');

    if (!defaultAction) {
      event.preventDefault();
    }

    const options = { sourceElement: target };

    const modalUrl = target.getAttribute('data-modal-url');
    if (isVariableDefinedNotNull(modalUrl) && modalUrl !== '') {
      options.url = modalUrl;
    }

    const modalUrlParams = target.getAttribute('data-modal-url-params');
    if (isVariableDefinedNotNull(modalUrlParams) && modalUrlParams !== '') {
      options.urlParams = modalUrlParams;
    }

    if (target.getAttribute('data-modal-force-open') === 'true') {
      options.forceOpen = true;
    }

    openModal(modalName, options);
  });
}

function initModal(element) {
  const modalName = element.getAttribute('data-modal-name');

  let ignoreClick = false;

  addListener(element, 'mousedown', (event) => {
    const target = event.target;

    ignoreClick = false;

    if (
      !target.hasAttribute('data-close-modal') &&
      !target.matches('.modal__close') &&
      target.closest('.modal__content > div')
    ) {
      ignoreClick = true;
    }
  });

  addListener(element, 'click', (event) => {
    const target = event.target;

    if (
      ignoreClick ||
      ['INPUT', 'TEXTAREA'].includes(document.activeElement.nodeName) ||
      target.hasAttribute('data-modal-keep-open') ||
      (!target.hasAttribute('data-close-modal') &&
        !target.matches('.modal__close') &&
        target.closest('.modal__content > div'))
    ) {
      return;
    }

    const modalContent = element.querySelector('[data-controller="modal-content"]');
    if (modalContent && modalContent.hasAttribute('data-modal-prevent-close')) {
      return;
    }

    hideModal(modalName);
  });

  if (element.hasAttribute('data-show-modal') && element.getAttribute('data-show-modal') === 'true') {
    openModal(element.getAttribute('data-modal-name'));
  }
}

function getForegroundModal() {
  let modalElement = document.querySelectorAll(
    '.modal[style*="display: block"], [data-component-controller="general--modal2"][style*="display: block"], [data-controller~="fuse--modal"]:not([hidden])',
  );

  if (!modalElement.length) {
    return undefined;
  }

  modalElement = [...modalElement].reverse().sort((a, b) => {
    const zIndexA = parseInt(window.getComputedStyle(a).zIndex, 10);
    const zIndexB = parseInt(window.getComputedStyle(b).zIndex, 10);
    return zIndexA - zIndexB;
  })[0];

  if (!modalElement || !isVisible(modalElement)) return undefined;

  return modalElement;
}

function registerModal() {
  GlobalObserver.register('[data-open-modal]', (element) => {
    initOpen(element);
  });

  GlobalObserver.register('.modal', (element) => {
    initModal(element);
  });

  addListener(window, 'keyup', (event) => {
    if ((!event.keyCode || event.keyCode !== 27) && (!event.key || event.key !== 'Escape')) return;

    const modalElement = getForegroundModal();
    if (!modalElement) return;

    const keepOpen = modalElement.querySelector('[data-modal-keep-open="true"]');
    if (keepOpen) return;

    if (modalElement.__fuseModalComponent && modalElement.__fuseModalComponent.persistent) return;

    hideModal(modalElement.dataset.modalName);

    event.preventDefault();
    event.stopPropagation();
  });
}

export default registerModal;
