import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['row', 'selectedCheckbox', 'details', 'detailsContent', 'detailsSpinner', 'detailsSpinnerTemplate'];
  }

  get itemValue() {
    return this.element.dataset.itemValue;
  }

  // Item selection

  toggleSelected(selected) {
    if (!this.hasSelectedCheckboxTarget) return;

    if (selected === undefined) {
      selected = !this.selectedCheckboxTarget.checked;
    }

    this.selectedCheckboxTarget.checked = selected;
  }

  dispatchSelectionChange(event) {
    if (event && 'shiftKey' in event && event.shiftKey) {
      window.getSelection().empty();
    }

    this.dispatch('selectionChange', {
      detail: {
        selected: this.selected,
        item: this.element,
        hasShiftKey: 'shiftKey' in event,
        shiftKey: !!(event && event.shiftKey),
      },
    });
  }

  get selected() {
    if (!this.hasSelectedCheckboxTarget) {
      return false;
    }

    return this.selectedCheckboxTarget.checked;
  }

  get selectedValue() {
    if (!this.hasSelectedCheckboxTarget) {
      return undefined;
    }

    return this.selectedCheckboxTarget.dataset.selectedValue;
  }

  // Details row toggle - DEPRECATED

  toggleDetails(event) {
    if (!this.hasDetailsTarget) {
      return;
    }

    if (this.preventToggle(event.target)) {
      return;
    }

    const newHidden = !this.detailsTarget.hidden;

    if (newHidden) {
      this.hideDetailsSpinner();
      this.hideDetails();
    } else {
      this.showDetailsSpinner();
      this.loadContent();
    }
  }

  hideDetailsSpinnerAndShowContent(event) {
    if (event.target !== this.detailsContentTarget) {
      return;
    }

    this.hideDetailsSpinner();

    if (!this.hasSpinnerInDetails) {
      this.showDetails();
    }
  }

  loadContent() {
    this.detailsContentTarget.loading = 'eager';
    this.detailsContentTarget.reload();
  }

  showDetailsSpinner() {
    if (this.hasSpinnerInDetails) {
      this.detailsContentTarget.innerHTML = this.detailsSpinnerTemplateTarget.innerHTML;

      this.showDetails();
    } else if (this.hasDetailsSpinnerTarget) {
      this.detailsSpinnerTarget.hidden = false;
      this.detailsSpinnerTarget.setAttribute('aria-hidden', false);
    }
  }

  hideDetailsSpinner() {
    if (this.hasDetailsSpinnerTarget) {
      this.detailsSpinnerTarget.hidden = true;
      this.detailsSpinnerTarget.setAttribute('aria-hidden', true);
    }
  }

  showDetails() {
    this.detailsTarget.hidden = false;
    this.detailsTarget.setAttribute('aria-hidden', false);
  }

  hideDetails() {
    this.detailsContentTarget.loading = 'lazy';

    this.detailsTarget.hidden = true;
    this.detailsTarget.setAttribute('aria-hidden', true);
  }

  preventToggle(target) {
    const ignoredElements = ['INPUT', 'SELECT', 'TEXTAREA', 'BUTTON', 'A'];
    if (ignoredElements.includes(target.tagName.toUpperCase())) return true;
    if (target.closest('[data-tooltip]')) return true;

    return false;
  }

  get hasSpinnerInDetails() {
    return !this.hasDetailsSpinnerTarget && this.hasDetailsSpinnerTemplateTarget;
  }
}
