import { eraseCookie, readCookie } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['column', 'option'];
  }

  initialize() {
    this.props = {
      columnsData: [],
      optionsData: [],
    };
  }

  connect() {
    this.loadFromCookie();
  }

  loadFromCookie() {
    const cookie = JSON.parse(window.localStorage.getItem(this.cookieName) || readCookie(this.cookieName));

    window.localStorage.setItem(this.cookieName, JSON.stringify(cookie));

    eraseCookie(this.cookieName);

    if (!cookie) {
      return;
    }

    this.columnsData = cookie.columns;
    this.optionsData = cookie.options;

    this.columnTargets.forEach((columnElement) => {
      columnElement.checked = cookie.columns.includes(this.columnName(columnElement));
    });

    this.optionTargets.forEach((optionElement) => {
      optionElement.checked = cookie.options.includes(this.optionName(optionElement));
    });
  }

  saveToCookie() {
    window.localStorage.setItem(
      this.cookieName,
      JSON.stringify({ columns: this.columnsData, options: this.optionsData }),
    );
  }

  update() {
    this.throttledUpdate();
  }

  throttledUpdate() {
    const settingsData = this.settingsData;

    this.columnsData = settingsData.columns;
    this.optionsData = settingsData.options;

    this.saveToCookie();

    setTimeout(this.triggerChanged.bind(this, settingsData), 0);
  }

  triggerChanged(settingsData) {
    console.log('changed', this.cookieName, settingsData);
    this.dispatch('changed', {
      target: document,
      detail: settingsData,
    });
  }

  elementsToData(elements, previousData, nameGetter) {
    const data = [];
    let reload = false;

    for (const element of elements) {
      const name = nameGetter(element);

      if (element.dataset.reloadList === 'true') {
        reload = reload || previousData.includes(name) !== element.checked;
      }

      if (element.checked) {
        data.push(name);
      }
    }

    return { data, reload };
  }

  columnName(element) {
    return element.dataset.columnName || element.value;
  }

  optionName(element) {
    return element.dataset.optionName || element.value;
  }

  get columnsData() {
    return this.props.columnsData;
  }

  set columnsData(value) {
    this.props.columnsData = value;
  }

  get optionsData() {
    return this.props.optionsData;
  }

  set optionsData(value) {
    this.props.optionsData = value;
  }

  get cookieName() {
    return this.identifier;
  }

  get settingsData() {
    const columnsData = this.elementsToData(this.columnTargets, this.columnsData, this.columnName);
    const optionsData = this.elementsToData(this.optionTargets, this.optionsData, this.optionName);

    return {
      columns: columnsData.data,
      options: optionsData.data,
      reload: columnsData.reload || optionsData.reload,
    };
  }
}
